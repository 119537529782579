export interface PageAttributes {
    consumerId: string;
    name: string;
    slug: string;
    body: SectionItem[];
}

export interface ImageUrl {
    data: { attributes: { url: string } };
}

export interface CmsPwaIcon {
    x48: ImageUrl;
    x72: ImageUrl;
    x96: ImageUrl;
    x128: ImageUrl;
    x192: ImageUrl;
    x384: ImageUrl;
    x512: ImageUrl;
}

export interface CmsPwaSplash {
    ipad: ImageUrl;
    ipadpro1: ImageUrl;
    ipadpro2: ImageUrl;
    ipadpro3: ImageUrl;
    iphone5: ImageUrl;
    iphone6: ImageUrl;
    iphoneplus: ImageUrl;
    iphonex: ImageUrl;
    iphonexr: ImageUrl;
    iphonexsmax: ImageUrl;
}

export interface PwaAttributes {
    name: string;
    short_name: string;
    description: string;

    icon_light: CmsPwaIcon;
    icon_dark: CmsPwaIcon;
    icon_light_maskable: CmsPwaIcon;
    icon_dark_maskable: CmsPwaIcon;
    splash_light: CmsPwaSplash;
    splash_dark: CmsPwaSplash;

    push_icon: ImageUrl;
    push_badge: ImageUrl;
    pwa_instructions_iphone: ImageUrl;
    pwa_instructions_ipad: ImageUrl;
}

export interface PwaResources {
    name: string;
    shortName: string;
    description: string;

    iconLight: {
        iconX48Url: string;
        iconX72Url: string;
        iconX96Url: string;
        iconX128Url: string;
        iconX192Url: string;
        iconX384Url: string;
        iconX512Url: string;
    };
    iconDark: {
        iconX48Url: string;
        iconX72Url: string;
        iconX96Url: string;
        iconX128Url: string;
        iconX192Url: string;
        iconX384Url: string;
        iconX512Url: string;
    };
    iconLightMaskable: {
        iconX48Url: string;
        iconX72Url: string;
        iconX96Url: string;
        iconX128Url: string;
        iconX192Url: string;
        iconX384Url: string;
        iconX512Url: string;
    };
    iconDarkMaskable: {
        iconX48Url: string;
        iconX72Url: string;
        iconX96Url: string;
        iconX128Url: string;
        iconX192Url: string;
        iconX384Url: string;
        iconX512Url: string;
    };
    splashLight: {
        splashIpadUrl: string;
        splashIpadPro1Url: string;
        splashIpadPro2Url: string;
        splashIpadPro3Url: string;
        splashIPhone5Url: string;
        splashIPhone6Url: string;
        splashIPhonePlusUrl: string;
        splashIPhoneExUrl: string;
        splashIPhoneExrUrl: string;
        splashIPhoneXsMaxUrl: string;
    };
    splashDark: {
        splashIpadUrl: string;
        splashIpadPro1Url: string;
        splashIpadPro2Url: string;
        splashIpadPro3Url: string;
        splashIPhone5Url: string;
        splashIPhone6Url: string;
        splashIPhonePlusUrl: string;
        splashIPhoneExUrl: string;
        splashIPhoneExrUrl: string;
        splashIPhoneXsMaxUrl: string;
    };
}

export interface BlogListAttributes {
    header: any;
    title: string;
    columns: BlogColumnType;
    bottomPostText: string;
    showApplyButton: boolean;
}

export interface BlogPostAttributes {
    title: string;
    slug: string;
    image: Image;
    previewText: string;
    body: SectionItem[];
    description: string;
    keywords: string;
}

export interface SectionItem {
    sectionType: string;
}

export type BlogColumnType = 'one' | 'two' | 'three' | 'four';

// TODO: cut deprecated interfaces Menu, MenuDropdown, MenuItem
export interface Menu {
    consumerId: string;
    name: string;
    body: Array<MenuItem | MenuDropdown>;
}

export interface MenuDropdown {
    icon: Image;
    title: string;
    link: string;
    items: MenuItem[];
}

export interface MenuItem {
    icon: Image;
    title: string;
    link: string;
}

export enum RESOURCE {
    // common
    menu = 'menus',
    sidebar = 'sidebars',
    footer = 'footers',
    websiteMetadata = 'website-metadatas',

    // pages
    page = 'pages',
    pwas = 'pwas',
    holidays = 'holiday-pages',
    blogList = 'blog-lists',
    blogPost = 'blog-posts',
}

export interface ApiResponse {
    data: ResponseData[];
    meta: ResponseMeta;
}

export interface ResponseData {
    id: number;
    attributes:
    | PageAttributes
    | BlogListAttributes
    | BlogPostAttributes
    | PwaAttributes;
}

export interface ResponseMeta {
    pagination: Pagination;
}

export interface Pagination {
    page: number;
    pageSize: number;
    pageCount: number;
    total: number;
}

export interface Image {
    data?: ImageData;
}

export interface ImageData {
    id: number;
    attributes: ImageAttributes;
}

export interface ImageAttributes {
    name: string;
    alternativeText: string;
    caption: string;
    width: number;
    height: number;
    formats?: ImageFormats;
    hash: string;
    ext: string;
    mime: string;
    size: number;
    url: string;
    previewUrl: string;
    provider: string;
    provider_metadata: unknown;
    createdAt: string;
    updatedAt: string;
}

export interface ImageFormats {
    thumbnail?: ImageFormat;
    small?: ImageFormat;
    medium?: ImageFormat;
    large?: ImageFormat;
}

export interface ImageFormat {
    ext: string;
    url: string;
    hash: string;
    mime: string;
    name: string;
    path: unknown;
    size: number;
    width: number;
    height: number;
}

export interface PageProps {
    page?: ResponseData | null;
    styles?: any;
    menu?: any;
    sidebar?: any;
    footer?: any;
    blog?: any;
    posts?: any;
    pagination?: {
        limit: number;
        hasMore: boolean;
    };

    authorized?: boolean;
}

export interface RedirectProps {
    permanent: boolean;
    destination: string;
}

export interface SectionProps {
    data?: any;
    styles?: any;
}
