const RX_HP = /\s*:\s*/;
const RX_CI = /\s*,\s*/;

const cluster: { host: string; port: number }[] = (
    process.env.REDIS_CLUSTER || 'localhost:6379'
).split(RX_CI).map(cfg => {
    const [host, port] = cfg.split(RX_HP);
    return { host, port: +port };
});

export const CACHE_HOST = process.env.CACHE_HOST || cluster[0].host;
export const CACHE_PORT = +(process.env.CACHE_PORT || cluster[0].port);
export const CACHE_MAX_TTL = +(process.env.CACHE_MAX_TTL || 60000);
export const DEBUG = !!process.env.DEBUG;

export default cluster;
