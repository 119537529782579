import { Theme, useTheme } from '@mui/material/styles';
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

function CheckboxCheckedIcon(props: SvgIconProps) {
    const theme = useTheme<Theme>();

    return (
        <SvgIcon
            viewBox={'0 0 25 20'}
            transform='scale(1.25)'
            fill={'none'}
            xmlns={'http://www.w3.org/2000/svg'}
            {...props}
            style={{
                position: 'inherit',
                left: '3px',
            }}
        >
            <rect
                x='0.5'
                y='0.5'
                width='19'
                height='19'
                stroke={theme.palette.text.primary}
                fill='transparent'
            />
            <path
                d={
                    'M10.8125 16.3125L3.8125 9.3125L5.15625 7.96875L10.8125 13.625L22.8125 1.625L24.1562 2.96875L10.8125 16.3125Z'
                }
                fill={theme.palette.text.primary}
            />
        </SvgIcon>
    );
}

export default CheckboxCheckedIcon;
