import { IncomingMessage } from 'http';
import axios from 'axios';
import { CONSUMER_ID_HEADER } from '../../config';
import {
    ApiResponse,
    PwaAttributes,
    PwaResources,
    RESOURCE,
} from '../types/CmsEntities';
import getConfigWithRequest from '../helpers/getConfigWithRequest';
import { cache, generateKey } from '../StaticCache';
import asyncLogger from '../logger';

export type CmsClientInput = {
    cmsApiUrl: string;
    consumerId: string;
};

export const cmsClient = async (input: CmsClientInput) => {
    const { cmsApiUrl, consumerId } = input;

    return axios.create({
        baseURL: cmsApiUrl,
        headers: { [CONSUMER_ID_HEADER]: consumerId },
    });
};

export default class CmsClient {
    public static async getEntities(
        req: IncomingMessage,
        resource: RESOURCE,
        query?: string,
    ): Promise<ApiResponse> {
        const { consumerId, cmsApiUrl } = await getConfigWithRequest(req);
        const slug = query ? `${ resource }?${ query }` : `${ resource }`;
        const cacheKey = generateKey(consumerId, 'entity', slug);
        const cachedData = await cache.get(cacheKey);

        if (cachedData) {
            return cachedData;
        }

        const input = { consumerId, cmsApiUrl };

        const { data } = await (await cmsClient(input)).get(
            query ? `/${ resource }?${ query }` : `/${ resource }`,
        ) || {};

        await cache.set(cacheKey, data);

        return data;
    }

    public static async getEntityBySlug(
        req: IncomingMessage,
        resource: RESOURCE,
        slug: string,
    ): Promise<ApiResponse> {
        const { consumerId, cmsApiUrl } = await getConfigWithRequest(req);
        const path = `${ resource }?filters[slug][$eq]=${ slug }`;
        const cacheKey = generateKey(consumerId, 'entity', path);
        const cachedData = await cache.get(cacheKey);

        if (cachedData) {
            return cachedData;
        }

        const input = { consumerId, cmsApiUrl };
        const { data } = await (await cmsClient(input)).get(`/${ path }`);

        await cache.set(cacheKey, data);

        return data;
    }

    public static async getPwaResources(
        req: IncomingMessage,
    ): Promise<PwaResources | null> {
        try {
            const { consumerId } = await getConfigWithRequest(req);
            const cacheKey = generateKey(consumerId, 'pwa');
            const cachedData = await cache.get(cacheKey);

            if (cachedData) {
                return cachedData;
            }

            const {
                data: [{ attributes: attributes }],
            } = await CmsClient.getEntities(req, RESOURCE.pwas);

            // noinspection SpellCheckingInspection
            const {
                name,
                short_name: shortName,
                description,

                icon_light: {
                    x48: { data: { attributes: { url: iconX48UrlLight } } },
                    x72: { data: { attributes: { url: iconX72UrlLight } } },
                    x96: { data: { attributes: { url: iconX96UrlLight } } },
                    x128: { data: { attributes: { url: iconX128UrlLight } } },
                    x192: { data: { attributes: { url: iconX192UrlLight } } },
                    x384: { data: { attributes: { url: iconX384UrlLight } } },
                    x512: { data: { attributes: { url: iconX512UrlLight } } },
                },
                icon_dark: {
                    x48: { data: { attributes: { url: iconX48UrlDark } } },
                    x72: { data: { attributes: { url: iconX72UrlDark } } },
                    x96: { data: { attributes: { url: iconX96UrlDark } } },
                    x128: { data: { attributes: { url: iconX128UrlDark } } },
                    x192: { data: { attributes: { url: iconX192UrlDark } } },
                    x384: { data: { attributes: { url: iconX384UrlDark } } },
                    x512: { data: { attributes: { url: iconX512UrlDark } } },
                },
                icon_light_maskable: {
                    x48: { data: {
                        attributes: { url: iconX48UrlLightMaskable },
                    }},
                    x72: { data: {
                        attributes: { url: iconX72UrlLightMaskable },
                    }},
                    x96: { data: {
                        attributes: { url: iconX96UrlLightMaskable },
                    }},
                    x128: {
                        data: { attributes: { url: iconX128UrlLightMaskable } },
                    },
                    x192: {
                        data: { attributes: { url: iconX192UrlLightMaskable } },
                    },
                    x384: {
                        data: { attributes: { url: iconX384UrlLightMaskable } },
                    },
                    x512: {
                        data: { attributes: { url: iconX512UrlLightMaskable } },
                    },
                },
                icon_dark_maskable: {
                    x48: { data: {
                        attributes: { url: iconX48UrlDarkMaskable },
                    }},
                    x72: { data: {
                        attributes: { url: iconX72UrlDarkMaskable },
                    }},
                    x96: { data: {
                        attributes: { url: iconX96UrlDarkMaskable },
                    }},
                    x128: {
                        data: { attributes: { url: iconX128UrlDarkMaskable } },
                    },
                    x192: {
                        data: { attributes: { url: iconX192UrlDarkMaskable } },
                    },
                    x384: {
                        data: { attributes: { url: iconX384UrlDarkMaskable } },
                    },
                    x512: {
                        data: { attributes: { url: iconX512UrlDarkMaskable } },
                    },
                },
                splash_light: {
                    ipad: { data: { attributes: { url: splashIpadUrlLight } } },
                    ipadpro1: {
                        data: { attributes: { url: splashIpadPro1UrlLight } },
                    },
                    ipadpro2: {
                        data: { attributes: { url: splashIpadPro2UrlLight } },
                    },
                    ipadpro3: {
                        data: { attributes: { url: splashIpadPro3UrlLight } },
                    },
                    iphone5: {
                        data: { attributes: { url: splashIPhone5UrlLight } },
                    },
                    iphone6: {
                        data: { attributes: { url: splashIPhone6UrlLight } },
                    },
                    iphoneplus: {
                        data: { attributes: { url: splashIPhonePlusUrlLight } },
                    },
                    iphonex: {
                        data: { attributes: { url: splashIPhoneExUrlLight } },
                    },
                    iphonexr: {
                        data: { attributes: { url: splashIPhoneExrUrlLight } },
                    },
                    iphonexsmax: { data: {
                        attributes: { url: splashIPhoneXsMaxUrlLight },
                    }},
                },
                splash_dark: {
                    ipad: {
                        data: { attributes: { url: splashIpadUrlDark } },
                    },
                    ipadpro1: {
                        data: { attributes: { url: splashIpadPro1UrlDark } },
                    },
                    ipadpro2: {
                        data: { attributes: { url: splashIpadPro2UrlDark } },
                    },
                    ipadpro3: {
                        data: { attributes: { url: splashIpadPro3UrlDark } },
                    },
                    iphone5: {
                        data: { attributes: { url: splashIPhone5UrlDark } },
                    },
                    iphone6: {
                        data: { attributes: { url: splashIPhone6UrlDark } },
                    },
                    iphoneplus: {
                        data: { attributes: { url: splashIPhonePlusUrlDark } },
                    },
                    iphonex: {
                        data: { attributes: { url: splashIPhoneExUrlDark } },
                    },
                    iphonexr: {
                        data: { attributes: { url: splashIPhoneExrUrlDark } },
                    },
                    iphonexsmax: {
                        data: { attributes: { url: splashIPhoneXsMaxUrlDark } },
                    },
                },
            } = attributes as PwaAttributes;

            const result = {
                name,
                shortName,
                description,

                iconLight: {
                    iconX48Url: iconX48UrlLight,
                    iconX72Url: iconX72UrlLight,
                    iconX96Url: iconX96UrlLight,
                    iconX128Url: iconX128UrlLight,
                    iconX192Url: iconX192UrlLight,
                    iconX384Url: iconX384UrlLight,
                    iconX512Url: iconX512UrlLight,
                },
                iconDark: {
                    iconX48Url: iconX48UrlDark,
                    iconX72Url: iconX72UrlDark,
                    iconX96Url: iconX96UrlDark,
                    iconX128Url: iconX128UrlDark,
                    iconX192Url: iconX192UrlDark,
                    iconX384Url: iconX384UrlDark,
                    iconX512Url: iconX512UrlDark,
                },
                iconLightMaskable: {
                    iconX48Url: iconX48UrlLightMaskable,
                    iconX72Url: iconX72UrlLightMaskable,
                    iconX96Url: iconX96UrlLightMaskable,
                    iconX128Url: iconX128UrlLightMaskable,
                    iconX192Url: iconX192UrlLightMaskable,
                    iconX384Url: iconX384UrlLightMaskable,
                    iconX512Url: iconX512UrlLightMaskable,
                },
                iconDarkMaskable: {
                    iconX48Url: iconX48UrlDarkMaskable,
                    iconX72Url: iconX72UrlDarkMaskable,
                    iconX96Url: iconX96UrlDarkMaskable,
                    iconX128Url: iconX128UrlDarkMaskable,
                    iconX192Url: iconX192UrlDarkMaskable,
                    iconX384Url: iconX384UrlDarkMaskable,
                    iconX512Url: iconX512UrlDarkMaskable,
                },
                splashLight: {
                    splashIpadUrl: splashIpadUrlLight,
                    splashIpadPro1Url: splashIpadPro1UrlLight,
                    splashIpadPro2Url: splashIpadPro2UrlLight,
                    splashIpadPro3Url: splashIpadPro3UrlLight,
                    splashIPhone5Url: splashIPhone5UrlLight,
                    splashIPhone6Url: splashIPhone6UrlLight,
                    splashIPhonePlusUrl: splashIPhonePlusUrlLight,
                    splashIPhoneExUrl: splashIPhoneExUrlLight,
                    splashIPhoneExrUrl: splashIPhoneExrUrlLight,
                    splashIPhoneXsMaxUrl: splashIPhoneXsMaxUrlLight,
                },
                splashDark: {
                    splashIpadUrl: splashIpadUrlDark,
                    splashIpadPro1Url: splashIpadPro1UrlDark,
                    splashIpadPro2Url: splashIpadPro2UrlDark,
                    splashIpadPro3Url: splashIpadPro3UrlDark,
                    splashIPhone5Url: splashIPhone5UrlDark,
                    splashIPhone6Url: splashIPhone6UrlDark,
                    splashIPhonePlusUrl: splashIPhonePlusUrlDark,
                    splashIPhoneExUrl: splashIPhoneExUrlDark,
                    splashIPhoneExrUrl: splashIPhoneExrUrlDark,
                    splashIPhoneXsMaxUrl: splashIPhoneXsMaxUrlDark,
                },
            };

            await cache.set(cacheKey, result);

            return result;
        } catch(err) {
            asyncLogger.error(err);

            return null;
        }
    }
}
