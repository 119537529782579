import { IncomingMessage } from 'http';
import { SwResources } from './swCommon';
import CmsClient from '../../clients/CmsClient';
import {
    BlogPostAttributes,
    PageAttributes,
    PwaAttributes,
    RESOURCE,
} from '../../types/CmsEntities';
import path from 'path';
import getConfigWithRequest from '../getConfigWithRequest';
import { cache, generateKey } from '../../StaticCache';

declare const __non_webpack_require__: any;

export class ServiceWorkerInitializer {
    private readonly buildId = process.env.NEXT_BUILD_ID || '';
    private swResources: SwResources | undefined;

    async getSwResources(req: IncomingMessage): Promise<SwResources> {
        const { consumerId } = await getConfigWithRequest(req);
        const cacheKey = generateKey(consumerId, 'sw-resources');
        const cachedData = await cache.get(cacheKey);

        if (cachedData) {
            return cachedData;
        }

        this.swResources ??= await this.getCacheResourcesImpl(req);
        await cache.set(cacheKey, this.swResources);

        return this.swResources;
    }

    private async getCacheResourcesImpl(req: IncomingMessage)
    : Promise<SwResources> {
        const [
            { data: staticData },
            { data: blogData },
            { data: [{ attributes: pwaAttributes }] },
        ] = await Promise.all([
            CmsClient.getEntities(req, RESOURCE.page),
            CmsClient.getEntities(req, RESOURCE.blogPost),
            CmsClient.getEntities(req, RESOURCE.pwas),
        ]);

        const staticPages = staticData
            .map(p => (p.attributes as PageAttributes).slug)
            .filter(p => p !== 'home');
        const blogPages = blogData
            .map(p => (p.attributes as BlogPostAttributes).slug);

        const componentChunks = this.getComponentChunks();

        const { push_icon: pushIcon, push_badge: pushBadge } =
            pwaAttributes as PwaAttributes;

        // noinspection SpellCheckingInspection
        return {
            requiredPages:[ 'error/offline' ],
            pagesToCache: [
                '/',
                'app',
                'app/login',
                'blog',

                ...staticPages,
                ...blogPages.map(p => `blog/${p}`),
                ...this.getChunksByComponent(
                    componentChunks,
                    'Pages/Login',
                    'Pages/Logout',
                    'Pages/RemindPassword',
                ),
            ],

            resourcesToCache: [
                'api/app?env=pwa',
                '/favicon.ico',
                '/manifest.json',
                `_next/data/${this.buildId}/index.json`,
                `_next/data/${this.buildId}/app.json`,
                `_next/data/${this.buildId}/app/profile.json`,
                `_next/data/${this.buildId}/app/login.json`,
                `_next/data/${this.buildId}/app/logout.json`,
                `_next/data/${this.buildId}/app/loan-history.json`,
                `_next/data/${this.buildId}/blog.json`,
                '_next/image?url=%2Ferror.png&w=96&q=75',

                ...staticPages.map(p =>
                    `_next/data/${this.buildId}/${p}.json?slug=${p}`),
                ...blogPages.map(p =>
                    `_next/data/${this.buildId}/blog/${p}.json?slug=${p}`),
            ],

            loginUpdates: [
                '/',
                'app',
                'app/login',
                'app/profile',
                'app/loan-history',

                'api/getAccountInfo',
                'api/getAccountInfo?customerId={customerId}',
                'api/getSubscriptions',

                ...this.getChunksByComponent(
                    componentChunks,
                    'Pages/Profile',
                    'Pages/Profile/LoanHistory',
                ),
            ],

            pushIcon: pushIcon.data.attributes.url,
            pushBadge: pushBadge.data.attributes.url,
        };
    }

    private getComponentChunks(): { [key: string]: string[] } {
        const loadableManifest = this.getLoadableManifest();

        return Object
            .keys(loadableManifest)
            .reduce((obj, key) => ({
                ...obj,
                [key.split(' -> ')[1]]: loadableManifest[key].files,
            }), {});
    }

    private getChunksByComponent(
        componentChunks: { [key: string]: string[] },
        ...components: string[]
    ): string[] {
        const chunks = components.reduce((arr, component) => {
            const keys = Object.keys(componentChunks);
            const index = keys.findIndex(p => p.endsWith(component));

            return index === -1
                ? arr
                : arr.concat(componentChunks[keys[index]]);
        }, [] as string[]);

        return [...new Set(chunks)].map(p => `_next/${ p }`);
    }

    private getLoadableManifest() {
        // https://github.com/vercel/next.js/discussions/34670
        // https://stackoverflow.com/questions/67969632/lazy-hydrate-code-splitting-on-nextjs-app
        return __non_webpack_require__(this.loadableManifestPath);
    }

    private get loadableManifestPath(): string {
        return path.join(
            process.cwd(),
            '.next',
            'react-loadable-manifest.json',
        );
    }
}

const serviceWorkerInitializer = new ServiceWorkerInitializer();

export { serviceWorkerInitializer };
