import { NextRequest } from 'next/server';
import { getClientIp } from 'request-ip';

export interface FormValue {
    value: string;
    error?: boolean;
}

export interface FormState {
    email: FormValue;
    fullName: FormValue;
    phone: FormValue;
    state: FormValue;
    subject: FormValue;
    message: FormValue;
}

export const RX_EMAIL =
    /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/
;
export const RX_BIRTHDATE
    = /^(0[1-9]|1[0-2])\/(0[1-9]|1\d|2\d|3[01])\/(19|20)\d{2}$/
;
export const RX_PASSWORD = /^(?=.*[a-zA-Z])(?=.*[0-9])/;
export const RX_SSN = /^\d{3}-?\d{2}-?\d{4}$/;
export const RX_NUMBER = /^\d+$/;
export const RX_PHONE_NUMBER = /((\(\d{3}\) ?)|(\d{3}-))?\d{3}-\d{4}/;

export function isDateOfBirth(input: any): boolean {
    return RX_BIRTHDATE.test(input + '');
}

export function isEmail(input: any): boolean {
    return RX_EMAIL.test(input + '');
}

export const hasError = (form: FormState): boolean => {
    if (!form) {
        return false;
    }
    return Object.values(form).some(({ error }) => error);
};

const RX_LIST_SPLIT = /\s*,\s*/;

export function accessDenied(req: NextRequest): boolean {
    const ips = ((process.env.ALLOWED_IPS || '').split(RX_LIST_SPLIT) || [])
        .filter(ip => ip);
    const clientIp = getClientIp(req as any);

    if (clientIp && ips.length) {
        // if client ip is not a part of allowed ips
        return !ips.includes(clientIp);
    }

    // if no network restriction set or no client IP defined (local)
    return false;
}
